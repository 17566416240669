import "./App.css";
import Logo from "./assets/logoBlack.svg";
import ScreenImg from './assets/screen.png'
import Background from './assets/background.jpg'
import Phone from './assets/callIcon.svg'
import Mail from './assets/MessageIcon.svg'
import { useNavigate } from 'react-router-dom';

function Support() {

  const navigate = useNavigate()

  return (
    <>
    <div
      className="App"
      style={{
        display: "flex",
        justifyContent: 'center',
        paddingBlock:60,
        flexWrap:'wrap',
        backgroundImage:`url(${Background})`,
        paddingLeft:120
      }}
    >
      <div
      className="container1"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems:'flex-start',
          maxWidth:'50%',
          // marginRight:'10%',
          // marginLeft:120,
        }}
      >
        <img src={Logo} onClick={()=>{navigate('/')}} alt="logo" width={100} height={40} />
        <h1
          style={{
            textAlign: "left",
            fontFamily: "Arimo",
            fontSize: 60,
            marginTop: "12%",
            maxWidth:570
          }}
        >
          {" "}
          Support
        </h1>
        <p
          style={{
            textAlign: "left",
            fontFamily: "Arimo",
            fontSize: 20,
            marginTop: 20,
            minWidth:280,
            maxWidth:570,
          }}
        >
        We're here to help you with any questions or issues you may have. Whether you're experiencing technical difficulties, need assistance with your account or have any other inquiries, feel free to reach out to us.
        </p>

        <div style={{marginTop:50}}>
          <div style={{display:'flex',  justifyContent:'', alignItems:'center'}}>
            <img alt="call" style={{marginRight:10}} height={20} width={20} src={Phone}/>
            <a
              style={{
                textDecoration:'none',
                textAlign: "left",
                fontFamily: "Arimo",
                fontWeight:'bold',
                color:'#000',
                fontSize: 20,
                minWidth:280
              }}
              href="tel:+972534718923"
              >
              +972 (53) 471-89-23
            </a>
          </div>

          <div style={{display:'flex', marginTop:20, justifyContent:'', alignItems:'center'}}>
            <img alt="phone" style={{marginRight:10}} height={20} width={20} src={Mail}/>
            <a
              style={{
                textDecoration:'none',
                textAlign: "left",
                fontFamily: "Arimo",
                fontWeight:'bold',
                color:'#000',
                fontSize: 20,
                minWidth:280
              }}
              href="mailto:info@skillwheel.co.il"
              >
              info@skillwheel.co.il
            </a>
          </div>
        </div>
        




      </div>

      <div 
      className="imagehand"
      style={{
        backgroundImage:`url(${ScreenImg})`,
        height:'100%',
        width:'50%',
        alignSelf:'flex-end',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat'
      }}>

      </div>

      
    </div>
    <div style={{
      height:60, 
      width:'100%',
      position:'fixed',
      bottom:0,
      backgroundColor:'#FF621A',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    }}>
      <p style={{
               fontFamily: "Arimo",
               fontSize:17,
               fontWeight:'bold',
               color:"#fff"
      }}>
        contact us -  
      </p>
      <a href="mailto:info@skillwheel.co.il?subject=Website%20support" style={{
               fontFamily: "Arimo",
               fontSize:17,
               fontWeight:'bold',
               color:"#fff"
      }}>
         info@skillwheel.co.il
      </a>
    </div>
    </>
  );
}

export default Support;
