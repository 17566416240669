import "./App.css";
import Logo from "./assets/logoBlack.svg";
import Countries from './assets/countries.svg'
import Gplay from './assets/googlePlay.svg'
import Astore from './assets/appstore.svg'
import ScreenImg from './assets/screen.png'
import Background from './assets/background.jpg'
import { useNavigate } from 'react-router-dom';

function App() {

  const navigate = useNavigate()

  return (
    <>
    <div
      className="App"
      style={{
        display: "flex",
        justifyContent: 'center',
        paddingBlock:60,
        flexWrap:'wrap',
        backgroundImage:`url(${Background})`,
        paddingLeft:120
      }}
    >
      <div
      className="container1"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems:'flex-start',
          maxWidth:'50%',
          // marginRight:'10%',
          // marginLeft:120,
        }}
      >
        <img src={Logo} alt="logo" width={100} height={40} />
        <h1
          style={{
            textAlign: "left",
            fontFamily: "Arimo",
            fontSize: 60,
            marginTop: "12%",
            maxWidth:570
          }}
        >
          {" "}
          Israel Driving Theory Test 2024{" "}
        </h1>
        <p
          style={{
            textAlign: "left",
            fontFamily: "Arimo",
            fontSize: 20,
            marginTop: 20,
            minWidth:280
          }}
        >
          6 Categories - A, B, C, C1, D, 1
        </p>
        <div
          className="langblock"
          style={{
            marginTop: 60,
            display: "flex",
            backgroundColor:'#F6F6F6',
            justifyContent:'flex-start',
            paddingInline:30,
            paddingBlock:20,
            borderRadius:10,
            flexWrap:'wrap'
          }}
        >
        <p style={{
            fontFamily: "Arimo",
            fontSize:25,
            fontWeight:'bold',
            minWidth:160,
            marginRight:20
        }}>6 Languages</p>
        <img className="cnImages" height={30} style={{}} alt="countries" src={Countries}/>
        </div>
        <h2 style={{
            fontFamily: "Arimo",
            fontSize:30,
            fontWeight:'lighter',
            textAlign:'left',
            marginTop:50,
            minWidth:500
        }}>+ Special learning program<br/>& Challenge</h2>

        <div
        className="storebtnsblock"
        style={{
          display:'flex',
          alignItems:'flex-start', 
          justifyContent:'flex-start',
          flexWrap:'wrap',
          marginTop:80
        }}>

          <button style={{
            backgroundImage: `url(${Gplay})`,
            height:60,
            width:202,
            backgroundSize:'cover',
            backgroundColor:'transparent',
            border:'none',
            marginRight:15,
            marginBottom:10
          }}/>

          <button style={{  
            backgroundImage: `url(${Astore})`,
            height:60,
            backgroundColor:'transparent',
            width:202,
            backgroundSize:'cover',
            border:'none'
          }}/>

        </div>



      </div>

      <div 
      className="imagehand"
      style={{
        backgroundImage:`url(${ScreenImg})`,
        height:'100%',
        width:'50%',
        alignSelf:'flex-end',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat'
      }}>

      </div>

      
    </div>
    <div
        onClick={()=>{navigate('/support')}}
    style={{
      height:60, 
      width:'100%',
      position:'fixed',
      bottom:0,
      backgroundColor:'#FF621A',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    }}>
      <p style={{
               fontFamily: "Arimo",
               fontSize:17,
               fontWeight:'bold',
               color:"#fff"
      }}>
      </p>
      <p 
              className="supportLink"
      style={{
               fontFamily: "Arimo",
               fontSize:17,
               textDecoration:'underline',
               fontWeight:'bold',
               color:"#fff"
      }}>
         Support
      </p>
    </div>
    </>
  );
}

export default App;
